.input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color:var(--whiteColor);
    
    input {
        border-radius: .8rem !important;
        background-color: var(--lightPrimaryColor) !important;
        border: none !important;
        padding: .5rem 1rem !important;
        width: 250px;
        width: 50%;
    }

    svg {
        position: absolute;
        left: 57%;
        top: .7rem;
        font-size: 1.2rem;
        color: var(--primaryColor)
    }

    ::placeholder {
        // color: var(--whiteColor) !important;
        color: rgb(60, 60, 232) !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

}