@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');




:root{
  --backgroundColor:#141820;
  --whiteColor:#fefeff;
  --primaryColor:#077afb;
  --lightPrimaryColor:#162032;
  --purpleColor:#9C27B0;
  --primaryHover:#013876;
}



body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  overflow: hidden;
  background-color: var(--backgroundColor) !important;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: 0.4s;

}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #84b0e1;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 1rem;
}




a {
  color: inherit;
  text-decoration: none;

}
.container{
  width: 90%;
  margin:auto
}