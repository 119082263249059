.Details__container {
    width: 90%;
    margin: auto;
}

.movieDetails {
    padding: 0 0 50px 0;

    .poster__section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;

        .poster__section-img {
            width: 32.5%;
            border-radius: 10px;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .poster__section-trailer {
            width: 65%;
            display: flex;
            flex-direction: column;

            iframe {
                width: 100%;
                height: 380px;
                border-radius: 10px;

            }

            .AliceCarouselDiv {
                width: 100%;

                .carouselItem {
                    display: flex;
                    flex-direction: column;

                    object-fit: contain;
                    padding: 10px;
                    color: var(--whiteColor);
                    margin-top: 20px;
                    margin-left: 20px;
                    padding-left: 40px;
                    width: 160px;
                    height: auto;
                }

                .carouselItem__img {
                    border-radius: 10px;
                    margin-bottom: 5px;
                    box-shadow: 0px 0px 5px black;
                    width: 100%;
                    gap: 2rem;
                }
            }


        }


    }

    .overview__section {
        height: 40%;
        overflow-y: scroll;
        padding: 15px;
        border-radius: 10px;
        scrollbar-width: thin;
        box-shadow: 2px 2px 25px 1px var(--primaryColor);
        border: 2px solid var(--primaryColor);
        text-align: justify;
        margin-top: 50px;
        color: var(--whiteColor);
        font-size: 1.3rem;

        &::-webkit-scrollbar {
            display: none;
        }

        span {
            display: block;
            width: 100%;
            font-weight: bold;
            padding-bottom: 10px;
        }
    }

    .similar__section {

        .spanSimilar {
            display: block;
            width: 100%;
            font-weight: bold;
            padding-bottom: 10px;
            color: var(--whiteColor);
            margin-top: 50px;
            font-size: 2rem;

        }
    }
}
@media(max-width:800px) {
    .movieDetails {
        padding: 0 0 50px 0;

        .poster__section {
            margin-top: 20px;
            flex-direction: column;

            .poster__section-img {
                width: 100%;
            }

            .poster__section-trailer {
                width: 100%;
                display: flex;
                flex-direction: column;

                iframe {
                    height: 400px;
                    margin-top: 20px;
                }

                .AliceCarouselDiv {
                    .carouselItem {
                        margin-left: 0px;
                        padding-left: 0px;
                        width: 170px;
                    }
                }
            }
        }

    }
}

@media(max-width:500px) {
    .movieDetails {
        padding: 0 0 50px 0;

        .poster__section {
            margin-top: 20px;
            flex-direction: column;

            .poster__section-img {
                width: 100%;
            }

            .poster__section-trailer {
                width: 100%;
                display: flex;
                flex-direction: column;

                iframe {
                    height: auto;
                    margin-top: 20px;
                }

                .AliceCarouselDiv {
                    .carouselItem {
                        margin-left: 0px;
                        padding-left: 0px;
                        width: 170px;
                    }
                }
            }
        }

    }
}